require('froala-editor/js/froala_editor.pkgd.min.js')

require('froala-editor/js/plugins/file.min.js')
require('froala-editor/js/plugins/image.min.js')
require('froala-editor/js/plugins/align.min.js')
require('froala-editor/js/plugins/colors.min.js')
require('froala-editor/js/plugins/paragraph_format.min.js')
require('froala-editor/js/plugins/lists.min.js')
require('froala-editor/js/plugins/link.min.js')
require('froala-editor/js/plugins/table.min.js')
require('froala-editor/js/plugins/emoticons.min.js')
require('froala-editor/css/plugins/table.min.css')
require('froala-editor/js/plugins/draggable.min.js')

require('froala-editor/js/plugins/url.min.js')


require('froala-editor/js/plugins/fullscreen.min.js')
require('froala-editor/css/plugins/fullscreen.min.css')

require('froala-editor/js/plugins/word_paste.min.js')

require('froala-editor/js/plugins/print.min.js')

require('froala-editor/js/plugins/file.min.js')



require('froala-editor/js/plugins/special_characters.min.js')
require('froala-editor/css/plugins/special_characters.min.css')

require('froala-editor/js/plugins/help.min.js')



import FroalaEditor from 'froala-editor';

FroalaEditor.DefineIconTemplate('fixImage', '<i class="fa-solid fa-wrench"></i>');
FroalaEditor.DefineIcon('fixImage', { NAME: 'fixImage', template: 'fixImage' });

FroalaEditor.RegisterCommand('fixImage', {
    title: 'fixImage',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback: function ( ) {

      console.log( this )

      //var img = this.image.get();
      //var src = img.attr('src')

      //let u = location.href.split('?')
      //if( u[1]) {
     //   const searchParams = new URLSearchParams(u[1]);
     //   let iid = searchParams.get("boardid")
     // }

    }})

FroalaEditor.DefineIcon('rotateImg', {NAME: 'redo', SVG_KEY: 'redo'});
FroalaEditor.RegisterCommand('rotateImg', {
    title: 'Rotate',
    focus: false,
    undo: false,
    refreshAfterCallback: false,
    callback: function ( ) {

      var img = this.image.get();
      var src = img.attr('src')
      //var optionnow = 0
      //var origfilename = ""      
     
      var iss3 = src.indexOf('s3-eu-west-3.amazonaws.com/floweimage')
      
      
      if( iss3 > -1 ){
        
        var segments = src.split('/')

        //console.log( segments )
        var filename = segments.pop()


        //alert( img.attr('data-optionnow') )
        //optionnow = 
        //origfilename = img.attr('data-origfilename')

        //if( !origfilename ){
        //  origfilename = filename
        //}

        
        //alert( origfilename )

        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://imgproc.flowe.io/?i=' + filename, true);
        xhr.send();
        console.log(xhr, img, src, filename,  segments, iss3)
        xhr.onreadystatechange = function(){


          if(xhr.readyState === XMLHttpRequest.DONE) {
            var status = xhr.status;
            if (status === 0 || (status >= 200 && status < 400)) { 

              var data = JSON.parse(xhr.responseText)

              console.log( data)
              filename = data.filename

              var newsrc = 'https://s3-eu-west-3.amazonaws.com/flowe-editor/'+filename 
              //+ "?t=" + seed

              img.attr('src', "" )
              setTimeout( function(){
                img.attr('src', newsrc )
              }, 200)    
              //img.attr('data-origfilename', origfilename )


              /*if( optionnow == 6 ){
                optionnow = 0
              }else{
                optionnow = optionnow + 1
              }*/

              //alert( optionnow )

              //img.attr('data-optionnow', optionnow )
            }

              //console.log(xhr.responseText);
            } else {
              // Oh no! There has been an error with the request!
            }
          }

        //optionnow
        // https://imgproc.flowe.io/?o=4&i=281e9c015615addb28cb7790e6fb81ca.jpeg
        //https://profilepictures.flowe.io/?t=full&i=uploads1587410049267-uploads1582388157023-DSC_8993.JPG
        //Jquery.load( 'https://imgproc.flowe.io/?o=' + options[optionnow] + '&i=' + origfilename, function(){
        //  alert('')
        //})
        //img.attr('src', 'https://imgproc.flowe.io/?o=' + options[optionnow] + '&i=' + origfilename )
        

        /*setTimeout( function(){
          
        }, 200) */
        

        //1587655905825-281e9c015615addb28cb7790e6fb81ca.jpeg
        
        

      }
    

    }
  });

  //var siteurl = "https://deploy.flowe.io/static/img/"
  var siteurl = "http://app.flowe.io/static/img/"

  // Add the icon to the Chat GPT UI
  FroalaEditor.DefineIconTemplate('font_longtext', '<i class="far fa-comment-alt"></i>');
  FroalaEditor.DefineIcon('chatGPT', { NAME: 'chatgpt', template: 'font_longtext' });


  FroalaEditor.RegisterCommand('chatGPT', {
  title: 'Summary generator',
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback: function callback() {

    document.nmodal.chatGPT()

  }
  });

  //chatGPT


  // preview report


  FroalaEditor.DefineIconTemplate('report_preview', '<i class="fas fa-search"></i>');
  FroalaEditor.DefineIcon('reportPreview', { NAME: 'reportPreview', template: 'report_preview' });


  FroalaEditor.RegisterCommand('reportPreview', {
  title: 'Preview report',
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback: function callback() {

    document.nmodal.previewmodal()

  }
  });

  // preview report 

  // publish report

  FroalaEditor.DefineIconTemplate('report_publish', '<i class="fas fa-upload"></i>');
  FroalaEditor.DefineIcon('reportPublish', { NAME: 'reportPublish', template: 'report_publish' });


  FroalaEditor.RegisterCommand('reportPublish', {
  title: 'Publish report',
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback: function callback() {

    document.nmodal.publishmodal()

  }
  });




  // publish report


  // delete report

  

  FroalaEditor.DefineIconTemplate('report_delete', '<i class="fas fa-trash"></i>');
  FroalaEditor.DefineIcon('reportDelete', { NAME: 'reportDelete', template: 'report_delete' });


  FroalaEditor.RegisterCommand('reportDelete', {
  title: 'Delete report',
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback: function callback() {

    document.nmodal.trydeletenewsletter()

  }
  });


  // delete report 


  // expand report editor view


  FroalaEditor.DefineIconTemplate('report_expand', '<i class="fas fa-arrows-h"></i>');
  FroalaEditor.DefineIcon('reportExpand', { NAME: 'reportExpand', template: 'report_expand' });


  FroalaEditor.RegisterCommand('reportExpand', {
  title: 'Expand editor',
  focus: false,
  undo: false,
  refreshAfterCallback: true,
  callback: function( i ) {

    console.log( i,this, "i,e,o")
    document.nmodal.expandeditorview()

  }
  });
  
  // expand report editor view


  // expand report editor view

/*
  FroalaEditor.DefineIconTemplate('report_compress', '<i class="fas fa-compress"></i>');
  FroalaEditor.DefineIcon('reportCompress', { NAME: 'reportCompress', template: 'report_compress' });


  FroalaEditor.RegisterCommand('reportCompress', {
  title: 'Compress editor',
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback: function callback() {

    document.nmodal.compresseditorview()

  }
  });
  
  // expand report editor view
*/

  


  FroalaEditor.DefineIconTemplate('font_awesomegreen', '<i class="fas fa-[NAME] green"></i>');
  FroalaEditor.DefineIconTemplate('font_awesomered', '<i class="fas fa-[NAME] red"></i>');
  FroalaEditor.DefineIconTemplate('font_awesomeyellow', '<i class="fas fa-[NAME] yellow"></i>');
  FroalaEditor.DefineIconTemplate('font_awesomeblue', '<i class="fas fa-[NAME] blue"></i>');

  FroalaEditor.DefineIconTemplate('font_word', '<i class="fas fa-file-word" style="font-size:18px;"></i>');

  FroalaEditor.DefineIcon('insercheck', { NAME: 'check', template: 'font_awesomegreen' });
  FroalaEditor.RegisterCommand('insercheck', {
    title: 'Insert check',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'green.png">');
      this.undo.saveStep();
    }
  });
    
  FroalaEditor.DefineIcon('insertimes', { NAME: 'times', template: 'font_awesomered' });
  FroalaEditor.RegisterCommand('insertimes', {
    title: 'Insert cross',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'red.png">');
      this.undo.saveStep();
    }
  });

  FroalaEditor.DefineIcon('insertwarning', { NAME: 'exclamation', template: 'font_awesomeyellow' });
  FroalaEditor.RegisterCommand('insertwarning', {
    title: 'Insert warning',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'yellow.png">');
      this.undo.saveStep();
    }
  });

  FroalaEditor.DefineIcon('insertinformation', { NAME: 'info', template: 'font_awesomeblue' });
  FroalaEditor.RegisterCommand('insertinformation', {
    title: 'Insert information',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      this.html.insert('<img src="' + siteurl + 'blue.png">');
      this.undo.saveStep();
    }
  });


  FroalaEditor.DefineIcon('exportword', { NAME: 'file-word', template: 'font_word' });
  FroalaEditor.RegisterCommand('exportword', {
    title: 'Export to Word',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {


      //$('#exportword-1').addClass('fr-disabled')

      let btn = document.getElementById('exportword-1');
      
      if( btn && btn.classList ){
        btn.classList.add("fr-disabled");
      }else{
        let btns = document.querySelectorAll('[data-cmd="exportword"]');

        for (let i = 0; i < btns.length; i++) {

          btn = btns[i]

          if( btn && btn.classList ){
            btn.classList.add("fr-disabled");
          }

        }

        
      }    

      //let x = 
      document.nmodal.exporttoword( this , function( newurl ){ 

        //alert( newurl )
        //window.open( newurl )
        window.location.href  = newurl

        return false

        //location.href = newurl
      })

      //if( x != "" ){

     //   location.href = x
      //}
      //alert('inserttoword')
      //this.html.insert('<img src="' + siteurl + 'blue.png">');
      //this.undo.saveStep();
    }
  });